import React, { Component } from 'react';
import airplane from './airplane.png';
import logo from './logo.png';
import './App.css';

var validator = require("email-validator");
var Airtable = require('airtable');
Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'keyrnBQ1eAm12RVRi'
});
var base = Airtable.base('appUoSQJ9IjtCTV7J');

class App extends Component {

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    if(validator.validate(data.get('email'))){
      base('Mailing List').create([
        {
          "fields": {"Email": data.get('email')}
        }
      ], function(err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          window.alert("We will notify " + data.get('email') + " when we are ready.")
        });
      });
    } else {
      window.alert("Your email address is invalid.")
    }
  }

  render(){
    return (
      <div className="app">
        <div className="main">
          <img src={airplane} className="airplane" alt="logo" />
          <div className="frame">
            <img src={logo} className="logo" alt="logo" />
            <div className="text">Journey guides founders to start, incubate and accelerate their startup business online.</div>
            <form className="container" onSubmit={this.handleSubmit}>
              <input className="form" type="text" name="email" placeholder="Your Email Address"/>
              <button className="button">Get Early Access</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
